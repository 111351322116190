<template>
  <button class="ps-btn btn-sm" v-on:click="addToCart">
    {{ voucherAmount.amount | toCurrency }}
  </button>
</template>

<script>
export default {
  name: "AddVoucherAmountCart",
  props: {
    voucherAmount: Object
  },
  methods: {
    addToCart() {
      this.$emit('add-voucher-amount-to-cart', this.voucherAmount);
    }
  }
}
</script>

<style scoped>

</style>